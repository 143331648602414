import React from 'react';
import { Container } from 'emotion-flex';
import { FormattedMessage } from 'react-intl';

import SEO from '../components/SEO';
import Link from '../components/Link';
import Button from '../components/Button';
import { withLayout } from '../components/Layout';
import Illustration from '../assets/images/undraw_confirmation_2uy0.svg';
import { useBrowserLocale } from '../hooks';

const ButtonLink = Button.withComponent(Link);

const EmailChangeConfirmed = () => {
  useBrowserLocale();

  return (
    <>
      <SEO title="Email confirmed" />
      <Container>
        <div css={{ padding: '64px 0', textAlign: 'center' }}>
          <img
            src={Illustration}
            alt="Email confirmed"
            css={{ maxWidth: 480 }}
          />
          <h1>
            <FormattedMessage
              id="pages.email.emailConfirmed"
              defaultMessage="Email confirmed"
            />
          </h1>
          <p>
            <FormattedMessage
              id="pages.email.emailConfirmationSuccess"
              defaultMessage="Email confirmation was successful."
            />
          </p>
          <ButtonLink to="https://cara.app.link/hoGy2zXkdy">
            <FormattedMessage
              id="pages.email.continueToApp"
              defaultMessage="Continue to App"
            />
          </ButtonLink>
        </div>
      </Container>
    </>
  );
};

export default withLayout(EmailChangeConfirmed, {
  isNewLayout: true,
  hideLanguageSwap: true,
});
